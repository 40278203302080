@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --teal: #2A798B;
    --teal-dark: #2D5159;
    --teal-darker: #133840;
    --teal-light: #AAE5F2;
    --purple: #8C2A50;
    --purple-hover: #eec7d6;
    --gold: #b97438;
    --gold-hover: #eed9c7;
    --gold-dark: #A16530;
    --white: #f4fafc;
    --black: #233437;
    --transition-slow: 0.5s linear;
    --transition-fast: 0.2s linear;
}
  

body {
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Bitter', sans-serif;
    font-weight: 400;
    color: var(--teal-darker);
    margin-top: 0;
}

p, ul, a:not(.button), ::placeholder {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: var(--teal-dark);
    letter-spacing: 0.025rem;
}

.caption {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.875rem;
    letter-spacing: 0.025rem;
}

.caption--small, label {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.75rem;
    letter-spacing: 0.025rem;
}

label {
    text-transform: uppercase;
}

::placeholder {
    color: var(--gold);
    font-size: 0.875rem;
}

input, textarea {
    box-sizing: border-box;
    color: var(--teal-darker);
    font-family: 'Bitter', sans-serif;
}

ul {
    list-style-type: circle;
}

a:not(.button, .unstyled) {
    font-family: 'Montserrat', sans-serif;
    color: var(--purple);
    text-underline-offset: 0.2rem;
    transition: color var(--transition-fast), opacity var(--transition-fast);
}

a:not(.button, .unstyled):hover {
    color: var(--teal);
    opacity: 0.5;
}

a.unstyled, button.unstyled {
    font-family: 'Bitter', sans-serif;
    text-decoration: none;
    cursor: pointer;
    transition: color var(--transition-fast), opacity var(--transition-fast);
    background: none;
    border: none;
}

a.disabled, button.disabled,
a.unstyled:hover, button.unstyled:hover {
    opacity: 0.5;
}

a.disabled, button.disabled {
    cursor: disabled;
}

button:not(.unstyled),
.button:not(.unstyled) {
    display: block;
    text-decoration: none;
    font-family: 'Bitter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: var(--white);
    border: none;
    border-radius: 25px;
    padding: 0.95rem 0.15rem;
    background-image: linear-gradient(to left, var(--teal) 0%, var(--teal) 45%, var(--white) 60%, var(--white) 100%);
    background-size: 250%;
    background-position: 0% 100%;
    transition: background-position var(--transition-slow);
}

.button.button--outline {
    background-image: linear-gradient(to left, var(--white) 0%, var(--white) 45%, var(--teal) 60%, var(--teal) 100%);
    color: var(--teal);
}

.button.button--outline--purple {
    background-image: linear-gradient(to left, var(--white) 0%, var(--white) 45%, var(--purple) 60%, var(--purple) 100%);
    color: var(--purple);
}

.button.button--outline--gold {
    background-image: linear-gradient(to left, var(--white) 0%, var(--white) 45%, var(--gold-dark) 60%, var(--gold-dark) 100%);
    color: var(--gold-dark);
}

button:not(.unstyled) span,
.button:not(.unstyled) span {
    background-image: linear-gradient(to left, var(--teal) 0%, var(--teal) 45%, var(--teal-light) 60%, var(--teal-light) 100%);
    background-size: 250% 100%;
    padding: 0.8rem 1.2rem;
    border-radius: 25px;
    background-position: 100% 100%;
    transition: background-position var(--transition-slow);
}

.button.button--outline span {
    background-image: linear-gradient(to left, var(--white) 0%, var(--white) 45%, var(--teal-light) 60%, var(--teal-light) 100%);
}

.button.button--outline--purple span {
    background-image: linear-gradient(to left, var(--white) 0%, var(--white) 45%, var(--purple-hover) 60%, var(--purple-hover) 100%);
}

.button.button--outline--gold span {
    background-image: linear-gradient(to left, var(--white) 0%, var(--white) 45%, var(--gold-hover) 60%, var(--gold-hover) 100%);
}

button:not(.unstyled):hover,
.button:not(.unstyled):hover {
    background-position: 100% 100%;
}

button:not(.button--outline, .button--outline--purple, .button--outline--gold, .unstyled):hover,
.button:not(.button--outline, .button--outline--purple, .button--outline--gold, .unstyled):hover {
    color: var(--teal);
}

button:not(.unstyled):hover span,
.button:not(.unstyled):hover span {
    background-position: 0% 100%;
}

.after-border-transparent::after {
    border-color: transparent;
}

.fade-in {
    bottom: calc(100% + 8px);
}

.fade-in.fade-in-visible {
    animation: visible 0.5s;
}
  
@keyframes visible {
   from {
     opacity: 0;
     bottom: calc(100%);
   }
   to {
     opacity: 1;
     bottom: calc(100% + 8px);
   }
}
